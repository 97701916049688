@import "var";
@import "header";
@import "toggleBtn";
@import "spincylinder";
@import "artbox";
@import "pastWork";

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $bg-color;
}

body {
  font-family: "Courier New", "bitter", "Roboto", "Helvetica Nueu", "Helvetica",
    "Arial", san-serif;
  font-size: 15px;
}

.clear {
  clear: both !important;
}

.title {
  font-family: "Courier New", "Helvetica Nueu", "Helvetica", "Arial", serif;
  font-weight: 500;
}

.hide {
  display: none !important;
}

.view {
  position: relative;
  height: 100%;
}

section {
  padding: 20px;
}

.container {
  margin: 0 auto;
  max-width: 1100px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

.scroll-container {
  height: 100vh;
  min-height: 100vh;
  scroll-snap-type: y mandatory;
}

.grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  .grid-cell-4 {
    flex: 4 25%;
  }
  .grid-cell-3 {
    flex: 3 33%;
  }
  .grid-cell-2 {
    flex: 2 50%;
  }
}

.bg-gray {
  background-color: $fade-white;
}

section.contact-me {
  text-align: center;
  padding: 80px 0;
  background: rgba(255, 255, 255, 0.5);
  background-image: url("../images/london.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  h4 {
    color: $white;
  }

  .flex {
    display: flex;
    justify-content: center;
  }

  a {
    margin: 0 10px;
  }
  a i {
    text-align: center;
    height: 60px;
    width: 60px;
    display: inline-block;
    line-height: 65px;
    font-size: 30px;
    border-radius: 99px;
    border: solid 1px #333;
    background-color: white;
  }
  a.cta.linkedin i {
    color: #0976b4;
    border-color: $main-green;
  }
  a.cta.mail i {
    color: #0099cc;
    border-color: $main-green;
  }
  a.cta.linkedin i:hover {
    background-color: #0976b4;
    color: #fff;
  }
  a.cta.mail i:hover {
    background-color: #0099cc;
    color: #fff;
  }
  a.cta.github i {
    color: #666;
    border-color: $main-green;
  }
  a.cta.github i:hover {
    background-color: #666;
    color: #fff;
  }
}

.jumbotron.main {
  margin: 0;
  padding: 0;
  position: relative;
  height: calc(100%);
  z-index: 1;

  &:after {
    content: "";
    background-image: url("../images/top-bg.jpg");
    background-size: cover;
    background-position: left;
    opacity: 0.9;
    top: -5px;
    left: 0;
    bottom: 0;
    right: 0px;
    position: absolute;
    z-index: -1;
  }

  > div.bg {
    height: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $beige;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .content {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 60px;
    text-align: right;
    color: $white;
    font-family: "Palatino";
    h1 {
      word-wrap: break-word;
      font-size: 90px;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 10px;
      font-weight: 550;
    }
    .blue {
      color: $main-blue;
    }
  }

  .motto {
    color: $white;
    text-align: right;
    margin: 0 auto;
    font-size: 20px;
    font-style: italic;
    p {
      margin-bottom: 8px;
    }
    span {
      font-size: 24px;
    }
  }
}

a {
  text-decoration: none;
}

.spacing {
  margin: 0 5px;
}

.about-me-roller {
  height: 180px;
  text-align: center;
}

.about-me-pills {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  label {
    background-color: $main-blue;
    color: $fade-gray;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 10px;
  }
}

.divider::after {
  width: 60px;
  margin: 0 auto;
  content: " ";
  display: block;
  border-bottom: solid 5px $gray-dark;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: default;
}

div.title {
  text-align: center;
  margin-bottom: 50px;

  .section-heading span {
    font-weight: 500;
    font-size: 34px;
    color: $gray-dark;
    text-transform: uppercase;
    border-bottom: solid 5px #bbb;
    padding-bottom: 10px;
  }
}

h4 {
  font-size: 1.5em;
  margin-top: 1em;
  text-transform: uppercase;
}

.line-divider-inline {
  content: " ";
  border-top: solid 3px #bbb;
}

.center {
  text-align: center;
}

ul {
  padding: 0;
  li {
    margin: 0;
    list-style: none;
    color: $dark-gray;
  }
}

.cloud {
  min-height: 250px;
  background: url("../images/cloud.png") no-repeat center;
  background-image: url("../images/cloud.svg"), none;
  background-size: contain;
}

.languages {
  li {
    margin: 10px 0;
    text-transform: uppercase;
  }
}

.emphasis {
  color: $gray-dark;
  font-size: 1.3em;
  text-shadow: 1px 2px $fade-gray;
}

.container {
  max-width: 1300px;
  padding: 20px;
}

.about-me {
  max-width: 700px;
  margin: 0 auto;
  word-spacing: 2px;

  p {
    height: 100%;
    line-height: 30px;
  }
}

@media screen and (max-width: 500px) {
  .grid {
    display: block;
  }

  .grid-mobile {
    display: flex;
  }

  .jumbotron.main {
    .content {
      right: 30px;
      h1 {
        font-size: 60px;
      }
    }
    &:after {
      background-size: auto;
      background-position: top;
    }
  }

  .about-me-pills {
    flex-direction: column;
    align-items: center;
  }
}
