.cylinder-frame {
  transform-style: preserve-3d;
  width: 100%;
  transform: translateY(40px);
  perspective: 500px;
  -webkit-perspective: 500px;
  height: 50px;
  .cylinder {
    animation: spin 15s infinite linear;
    -webkit-animation: spin 15s infinite linear;
    -moz-animation: spin 10s infinite linear;
    transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 50px;

    p {
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      height: 20px;
      width: 100%;
      position: absolute;
    }
    p:nth-child(1) {
      transform: rotateX(0deg) translateZ(40px);
    }
    p:nth-child(2) {
      transform: rotateX(40deg) translateZ(40px);
    }
    p:nth-child(3) {
      transform: rotateX(80deg) translateZ(40px);
    }
    p:nth-child(4) {
      transform: rotateX(120deg) translateZ(40px);
    }
    p:nth-child(5) {
      transform: rotateX(160deg) translateZ(40px);
    }
    p:nth-child(6) {
      transform: rotateX(200deg) translateZ(40px);
    }
    p:nth-child(7) {
      transform: rotateX(240deg) translateZ(40px);
    }
    p:nth-child(8) {
      transform: rotateX(280deg) translateZ(40px);
    }
    p:nth-child(9) {
      transform: rotateX(320deg) translateZ(40px);
    }
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotateX(0deg);
  }
  to {
    -ms-transform: rotateX(-360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotateX(0);
  }
  to {
    -moz-transform: rotateX(-360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateX(0);
  }
  to {
    -webkit-transform: rotateX(-360deg);
  }
}
@keyframes spin {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-360deg);
  }
}
