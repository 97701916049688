$white: #fff;
$fade-white: #f8f8f8;
$fade-gray: #e7e7e7;
$gray: #c3c9cf;
$gray-dark: #8b8d8e;
$fade-green: #f0fff0;
$green: #cdffcc;
$fade-blue: #f0ffff;
$blue: #cdffff;
$dark-gray: #5d6164;
$darker-gray: #424242;
$divider-color: #424242;
$black: #000;
$beige: #f9f4ed;
$main-green: #80cbc4;
$bg-color: $white;
$main-blue: #4998c6;
