.toggle-cta {
  width: 40px;
  height: 40px;
  display: block;

  position: absolute;
  top: 0;
  right: 30px;
  z-index: 10;
  background-color: $gray-dark;
  border-radius: 99px;
  padding: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);

  .icon.mail {
    position: absolute;
    width: 40px;
    height: 40px;
    display: block;
    font-size: 38px;
    color: $white;
    text-align: center;
    z-index: 4;
  }

  .icon.doc {
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    position: absolute;
    width: 40px;
    height: 20px;
    display: block;
    font-size: 28px;
    color: $white;
    text-align: center;
    overflow: hidden;
    top: 15px;
    z-index: 3;
    opacity: 0;
  }
}

.toggle-cta:hover {
  .icon.doc {
    top: 0px;
    opacity: 1;
  }
}
