.artbox {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  .box-main {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }
  .box-title {
    border-top: solid 1px #ccc;
    background-color: #0099cc;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out;
    /* For Safari 3.1 to 6.0 */
    transition: all 0.3s ease-out;
    a,
    label {
      color: #fff;
      position: absolute;
      top: 40%;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 18px;
      > span {
        border-top: solid 1px #fff;
      }
      i {
        margin-left: 5px;
      }
    }
  }
}

.artboxbox:hover {
  .box-main {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  .box-title {
    opacity: 0.9;
    filter: alpha(opacity=90);
  }
}
