.header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  margin: 20px;

  .navbar {
    .navbar-header {
      float: none;
      margin: 0 20px;
    }

    .navbar-toggle {
      float: right;

      .icon-bar {
        background: $white;
      }
    }
  }

  //    .navbar-brand {
  //        padding: 5px;
  //        position: absolute;
  //        top: 0;
  //        left: 40px;
  //        img {
  //            width: 60px;
  //            display: inline-block;
  //        }
  //    }
}

.navbar-cta {
  position: fixed;
  bottom: 80px;
  right: 30px;
}

.side-navigation {
  list-style: none;
  text-align: right;

  > ul {
    padding: 0;
  }

  > ul > li {
    display: block;
    padding: 10px 15px;

    a {
      color: $white;
      text-transform: uppercase;
      display: inline;
      padding: 2px;
      letter-spacing: 1px;
      background-color: transparent !important;
      text-decoration: none;
      font-size: 1.2em;
    }
  }

  > ul > li:hover > a {
    border-bottom: solid 5px $gray-dark;
  }
}

@media screen and (max-width: 768px) {
  .side-navigation > ul > li {
    display: block;
  }

  .side-navigation {
    text-align: right;
  }

  .navbar-cta {
    right: 0px;
  }
}
